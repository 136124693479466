/* General Subscription Box Styling */
.subscription-box {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Subscribed Box */
.subscription-box.subscribed {
  border: 2px solid #28a745;
  background: #e6f8eb;
}

.subscription-box.subscribed h2 {
  color: #28a745;
}

/* Pending Cancellation Box */
.subscription-box.pending-cancellation {
  border: 2px solid #ffc107;
  background: #fff8e1;
}

.subscription-box.pending-cancellation h2 {
  color: #d39e00;
}

/* Not Subscribed Box */
.subscription-box.not-subscribed {
  border: 2px solid #dc3545;
  background: #fdecea;
}

.subscription-box.not-subscribed h2 {
  color: #dc3545;
}

/* Benefits List */
.benefits-list {
  list-style: none;
  padding: 0;
  margin: 15px 0;
}

.benefits-list li {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 8px 0;
}

.benefits-list i {
  font-size: 20px;
  margin-right: 10px;
  color: #007bff;
}

/* Unsubscribe Button */
.unsubscribe-btn {
  background: #dc3545;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
}

.unsubscribe-btn:hover {
  background: #c82333;
}

/* Subscribe Button */
.subscribe-btn {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
}

.subscribe-btn:hover {
  background: #0056b3;
}
